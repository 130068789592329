@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Balsamiq+Sans&family=Press+Start+2P&family=Shizuru&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  --clr-p-green1: #7ac231;
  --clr-p-green2: #a6d345;
  --clr-p-black: #1818EE;
  --clr-p-grey-l: #4c4d55;
  --clr-p-grey-m: #2a2b31;
  --clr-p-grey-d: #1d2024;
  --clr-p-grey1: #4c4d55;
  --clr-p-grey-vl: #3A3C43;
  --clr-p-grey-header-block: #606166;
  --clr-help-icon: #43444B;
  --clr-bg-tooltip: #7AC231;
  --clr-decrease: #da2c1d;
  --clr-primary-card: #1D2024;
}

/* font-family: 'Lato', sans-serif; */

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  max-width: 100%;
  overflow-y: auto;
  font-family: 'Lato', sans-serif;
  font-family: "Press Start 2P", cursive;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--clr-primary-card);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', "Press Start 2P",
    monospace;
}



input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

* {
  font-family: 'Lato', sans-serif !important;
}


.hideScrollBar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
}

.hideScrollBar::-webkit-scrollbar {
  display: none;
}

.topPillsWapper {
  overflow-y: scroll;
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}


.custom_load_more_btn {
  outline: none;
  margin: 0px auto;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  margin-top: 20px;
  background-color: #7AC231;
  color: #fff;
  border: none;
  border-radius: 8px;
  max-width: 1100px;
  width: 200px;
  height: 60px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 700;
}

/* mui auto complete style */

.css-viou3o-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 2px 6px !important;
  background-color: white;
  border: none;
  border-radius: 8px;
}

/* .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: none !important;
} */

.create_note_page .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 1px solid rgba(128, 128, 128, 0.246) !important;
}

#AutoCompleteSearch-label {
  margin-left: 7px !important;
}

#AutoCompleteSearch {
  background: white !important;
  border-radius: 8px !important;
}

.AutoCompleteSearch_items {
  gap: 2px;
  display: flex;
  justify-content: start;
  align-items: start;
  padding: 6px 10px;
  cursor: pointer;
}

.AutoCompleteSearch_items:hover {
  background-color: whitesmoke !important;
}

.AutoCompleteSearch_items img {
  height: 25px;
  width: 25px;
  margin-right: 8px;
}